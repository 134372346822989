import { CommonModule, DatePipe } from '@angular/common'
import {
  AfterContentInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import {
  AbstractControl,
  AbstractControlOptions,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms'
import { NgbCalendar, NgbDateAdapter, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap'
import { NgxDropzoneModule } from 'ngx-dropzone'
import { NgSelectModule } from '@ng-select/ng-select'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { Project } from 'app/pages/core/model/project.model'
import { ProjectService } from 'app/pages/core/services/project.service'
import { TaskService } from 'app/pages/core/services/task.service'
import { TimeSheetService } from 'app/pages/core/services/timesheet.service'
import { ModalComponent, ModalConfig } from 'app/_metronic/partials'
import { ModalsModule } from 'app/_metronic/partials/layout/modals/modals.module'
import { BehaviorSubject, Observable, of } from 'rxjs'
import { InlineSVGModule } from 'ng-inline-svg-2'
import { User } from 'app/pages/core/model/flexc-user.model'
import { Timesheet } from 'app/pages/core/model/timesheet.model'
import { ApiResponse } from 'app/pages/core/dto/ApiResponse'
import { environment } from 'environments/environment'
import { ActivatedRoute, Router } from '@angular/router'
import { SelectComponent } from '../select/select.component'
import { MultiSelectComponent } from '../multi-select/multi-select.component'
import { InputComponent } from '../input/input.component'
import { ProgressBarComponent } from '../progress-bar/progress-bar.component'
import { ProjectMember } from 'app/pages/core/model/projectUsers.model'
import { UserService } from 'app/pages/core/services/user.service'
import { TimesheetInputService } from '../timesheet-input.service'
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker'
import { NzFormModule } from 'ng-zorro-antd/form'

@Component({
  selector: 'app-timesheet-input',
  templateUrl: './timesheet-input.component.html',
  styleUrls: ['./timesheet-input.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    NgSelectModule,
    NzFormModule,
    FormsModule,
    ReactiveFormsModule,
    ModalsModule,
    NgbDatepickerModule,
    NgxDropzoneModule,
    InlineSVGModule,
    SelectComponent,
    MultiSelectComponent,
    InputComponent,
    ProgressBarComponent,
    NzTimePickerModule,
  ],
})
export class TimesheetInputComponent implements OnInit, OnChanges, AfterContentInit {
  @ViewChild('modalDeleteSuperUser') private modalDeleteSuperuserComponent: ModalComponent
  @ViewChild('modal') private modalComponent: ModalComponent

  @Input() openModalSubject!: BehaviorSubject<boolean>
  @Input() user: User
  @Input() timeSheet: Timesheet
  @Input() isUpdate: boolean = false
  @Input() userList: any[]

  @Output() modalCloseEmitter: EventEmitter<boolean> = new EventEmitter()
  @Output() modalSaveEmitter: EventEmitter<any> = new EventEmitter()

  titleForModal: BehaviorSubject<string> = new BehaviorSubject<string>(this.translate.instant('ENTER_NEW_ENTERY_FOR'))
  secondaryTitle: BehaviorSubject<string> = new BehaviorSubject<string>('')
  openModalDoubleBookingSubject$: BehaviorSubject<any> = new BehaviorSubject<any>({})
  modalBodyTitle: BehaviorSubject<string> = new BehaviorSubject<string>('')
  modalBodyTitleTransletable: string = ''
  getAllUserProjects$: Observable<Project[]> = of([])
  projectList: any[]
  getAllTasksByProjectId: any[] = new Array()
  files: File[] = []
  getAllUserProjects: Project[]
  getAllUnarchivedProjectsResponseOnly: Project[]
  timesheetForm: FormGroup
  submit: boolean = false
  project: Project
  daysLeft: number = 0
  projectBudget: number = 0
  total: number = 0
  specific: boolean = false
  roleAdmin: boolean = false
  timesheetObject: any
  outsideProjectPeriod: boolean = false
  differentInHours: number
  projectUser: any
  isLoadingProjectList: boolean = true
  selectedProjectUpdate!: any
  selectedTasksUpdate!: any
  usedAllocationInPercentage: number = 0
  usedAllocation: number = 0

  modalConfig: ModalConfig = {
    newModalTitle: this.titleForModal,
    secondaryTitle: this.secondaryTitle,
    closeButtonLabel: this.translate.instant('SAVE_AND_CLOSE'),
    onClose: () => {
      this.submitForm()
      return false
    },
    onDismiss: () => {
      this.isUpdate = false
      this.modalClose()
      return true
    },
  }

  modalConfigDeleteSuperUser: ModalConfig = {
    modalBodyTitle: this.modalBodyTitle,
    dismissButtonLabel: this.translate.instant('BACK'),
    closeButtonLabel: this.translate.instant('OK'),
    onClose: () => {
      this.checkIfTimeSheetIsDuplicate(this.timesheetObject)
      return true
    },
    onDismiss: () => {
      return true
    },
  }

  constructor(
    private ngbCalendar: NgbCalendar,
    private fb: FormBuilder,
    private dateAdapter: NgbDateAdapter<string>,
    private projectService: ProjectService,
    private timeSheetService: TimeSheetService,
    private taskService: TaskService,
    private datepipe: DatePipe,
    public translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private timesheetInputService: TimesheetInputService
  ) {}

  ngAfterContentInit(): void {
    this.openModalSubject.subscribe(res => {
      if (res) {
        this.getAllTasksByProjectId = []
        this.selectedProjectUpdate = null
        this.selectedTasksUpdate = null
        this.fetchProjectData()
        this.openModal()
        !this.isUpdate &&
          this.timesheetForm.patchValue({ startDate: this.dateAdapter.toModel(this.ngbCalendar.getToday()) })
        //if mass entry
        if (this.userList?.length > 0) {
          this.secondaryTitle.next('selected users')
          this.setupForSpecific()
        }
      }
      this.project && this.getCompanyAndProjectTasks(this.project.id)
      if (this.isUpdate) {
        this.selectedTasksUpdate = this.timeSheet?.descriptions[0]?.tasks
      }
    })
    this.translate.onLangChange.subscribe((res: any) => {
      this.titleForModal.next(this.translate.instant('ENTER_NEW_ENTERY_FOR'))
      // this.modalBodyTitleTransletable && this.modalBodyTitle.next(this.translate.instant(this.modalBodyTitleTransletable))
      this.modalConfigDeleteSuperUser = {
        dismissButtonLabel: this.translate.instant('BACK'),
        closeButtonLabel: this.translate.instant('OK'),
        ...this.modalConfigDeleteSuperUser,
      }
      this.modalConfig = {
        ...this.modalConfig,
        closeButtonLabel: this.translate.instant('SAVE_AND_CLOSE'),
      }
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes?.user?.firstChange && !this.isUpdate) {
      if (this.user) {
        this.titleForModal.next(this.translate.instant('ENTER_NEW_ENTERY_FOR'))
        this.secondaryTitle.next(this.user.firstName + ' ' + this.user.lastName)
      }
    }
    if (!changes?.timeSheet?.firstChange && this.isUpdate) {
      if (!this.timeSheet) {
        return
      }
      this.getTimesheetValue()
    }
  }

  ngOnInit(): void {
    this.timesheetForm = this.fb.group(
      {
        startDate: ['', [Validators.required]],
        project: ['', [Validators.required]],
        start: [null, Validators.required],
        startPause: [],
        endPause: [],
        end: [null, Validators.required],
        descriptions: this.fb.group({
          id: [],
          text: ['', Validators.required],
          tasks: [[]],
        }),
      },
      {
        validator: [this.ValidateStartBreakTime, this.ValidateEndBreakTime, this.ValidateEndWorkTime],
      } as AbstractControlOptions
    )

    this.setupForSpecific()
  }

  setupForSpecific() {
    if (this.userList?.length > 0) {
      this.route.parent?.params.subscribe(params => {
        if (params['id']) {
          this.getOneProjectCall(params['id'])
        }
      })
    } else {
      this.route.params.subscribe(params => {
        const param = this.router.url.includes('projects/project-overview') ? 'projectId' : 'id'
        if (params[param]) {
          this.getOneProjectCall(params[param])
        }
      })
    }
  }

  getOneProjectCall(param: string) {
    this.projectService.getOneProject(param).subscribe((res: ApiResponse<Project>) => {
      this.project = res.response
      this.userService
        .getUsersOnProject(param, 0, 50, 'user.firstName', 'ASC')
        .subscribe((res: ApiResponse<ProjectMember[]>) => {
          this.project.projectMembers = res.content
        })
      this.timesheetForm.get('project')?.setValue(this.project.id)
      this.specific = true
      this.project && this.getCompanyAndProjectTasks(this.project.id)
    })
  }

  fetchProjectData() {
    this.isLoadingProjectList = true
    if (
      !this.router.url.includes('projects/project-overview') &&
      !this.router.url.includes('timesheet/specific-timesheet')
    ) {
      this.getAllUserProjects$ = this.projectService.getUsersProjectTimesheetWithoutUserId()
      this.getAllUserProjects$.subscribe(res => {
        res = res?.filter(
          element => element.projectStatus?.name === 'RUNNING' || element.projectStatus?.name === 'PAUSED'
        )
        this.isLoadingProjectList = false
        this.projectList = res
          ?.map(item => ({
            ...item,
            label: item.name,
            value: item.id,
            disabled: item.projectStatus?.name === 'PAUSED',
            hidden: this.isUpdate
              ? false
              : item.projectMembers &&
                item.projectMembers.some(member => member.user.id === this.user.id && member.blocked),
          }))
          .filter(item => !item.hidden)
        if (this.isUpdate) {
          this.selectedProjectUpdate = this.timeSheet.project
          this.getCompanyAndProjectTasks(this.selectedProjectUpdate.id)
          this.selectedTasksUpdate = this.timeSheet?.descriptions[0]?.tasks
        } else {
          this.selectedProjectUpdate = null
        }
      })
    }
  }

  submitForm() {
    this.submit = true
    if (!this.timesheetForm.valid && !this.specific) {
      return
    } else {
      let splitDate = this.timesheetForm.controls['startDate'].value.split('-')
      let selectedDate = new Date(+splitDate[2], splitDate[1] - 1, +splitDate[0])
      const dateString = `${this.datepipe.transform(selectedDate, 'yyyy-MM-dd')}T`

      this.timesheetObject = {
        start: `${dateString}${this.datepipe.transform(this.timesheetForm.controls['start'].value, 'HH:mm')}`,
        end: `${dateString}${this.datepipe.transform(this.timesheetForm.controls['end'].value, 'HH:mm')}`,
        startPause: this.timesheetForm.controls['startPause']?.value
          ? `${dateString}${this.datepipe.transform(this.timesheetForm.controls['startPause'].value, 'HH:mm')}`
          : null,
        endPause: this.timesheetForm.controls['endPause']?.value
          ? `${dateString}${this.datepipe.transform(this.timesheetForm.controls['endPause'].value, 'HH:mm')}`
          : null,
        project: {
          id: this.specific ? this.project.id : this.timesheetForm.controls['project'].value,
        },
        descriptions: [
          {
            id: this.timesheetForm.controls['descriptions']?.value.id || undefined,
            text: this.timesheetForm.controls['descriptions']?.value.text,
            tasks: new Array(),
          },
        ],
        ...(!this.files?.length && { file: null }),
      }

      //asign tasks
      if (this.timesheetForm.controls['descriptions']!.value.tasks!) {
        this.timesheetForm.controls['descriptions']!.value.tasks!.forEach((task: string) => {
          this.timesheetObject.descriptions[0].tasks.push({
            id: task,
          })
        })
      }

      if (this.isUpdate) {
        this.sendReq(this.timesheetObject)
      } else {
        this.userList ? this.sendReq(this.timesheetObject) : this.checkDate(this.project, dateString)
      }
    }
  }

  sendReq(body: any) {
    this.outsideProjectPeriod = false
    if (!this.isUpdate) {
      //check if mass we have a mass entry
      if (this.userList?.length > 0) {
        let userListObject: any = new Array()
        for (const iterator of this.userList) {
          userListObject.push({ id: iterator.user.id })
        }

        let bodyWithUserList: any = {}
        bodyWithUserList.timeSheet = body
        bodyWithUserList.users = userListObject

        this.timeSheetService.massTimeSheetEntries(bodyWithUserList).subscribe((res: any) => {
          if (res.httpStatus === 'OK') {
            this.modalSaveEmitter.next(true)
            this.modalClose()
          } else if (res.httpStatus === 'BAD_REQUEST') {
            this.modalSaveEmitter.next(false)
          }
          this.closeModals()
        })
      } else {
        //this is single user new entry
        body.user = {
          id: this.user.id,
        }
        this.checkIfTimeSheetIsDuplicate(body)
      }
    } else {
      //case when its an update
      this.updateTimesheetApiCall(body)
    }
  }

  checkOverbooking() {
    if (this.usedAllocationInPercentage! < 100 && this.total! < this.usedAllocation!) {
      this.usedAllocation! -= this.differentInHours
    }
    this.differentInHours = 0
    this.projectUser = ''
    const diffInMs = Date.parse(this.timesheetObject.end) - Date.parse(this.timesheetObject.start)
    this.differentInHours = diffInMs / 1000 / 60 / 60
    this.differentInHours = (this.differentInHours / 10) * 1.25
    if (this.usedAllocation && this.total) {
      this.usedAllocation! = (this.usedAllocation! ?? 0) + this.differentInHours
      if (this.total! && this.usedAllocation! > this.total) {
        if (this.outsideProjectPeriod) {
          this.modalBodyTitleTransletable =
            'ATTENTION_YOU_ARE_OVERBOOKING_YOUR_PROJECT_BUDGET_AND_BOOKING_OUTSIDE_OF_THE_PROJECT_PERIOD_YOU_CAN_PROCEED_BUT_YOUR_PROJECT_MANAGER_WILL_BE_INFORMED'
          this.modalBodyTitle.next(this.translate.instant(this.modalBodyTitleTransletable))
        } else {
          this.modalBodyTitleTransletable =
            'ATTENTION_YOU_ARE_OVERBOOKING_YOUR_PROJECT_BUDGET_YOU_CAN_PROCEED_BUT_YOUR_PROJECT_MANAGER_WILL_BE_INFORMED'
          this.modalBodyTitle.next(this.translate.instant(this.modalBodyTitleTransletable))
        }
        this.modalDeleteSuperuserComponent.open({ windowClass: 'worning-body' })
      } else if (this.outsideProjectPeriod) {
        this.modalDeleteSuperuserComponent.open({ windowClass: 'worning-body' })
      } else {
        this.checkIfTimeSheetIsDuplicate(this.timesheetObject)
      }
    } else {
      this.checkIfTimeSheetIsDuplicate(this.timesheetObject)
    }
  }

  checkDate(project: Project, date: string) {
    this.outsideProjectPeriod = false
    this.timesheetObject.user = {
      id: this.user.id,
    }
    if (project) {
      this.modalBodyTitle.next('')
      this.outsideProjectPeriod = false
      const projectEnd = new Date(project.end)
      const projectStart = new Date(project.start)
      const dateTimeString = date + '00:00:00'
      const inputDate = new Date(dateTimeString)

      if (project.start && project.end && (projectEnd < inputDate || projectStart > inputDate)) {
        this.outsideProjectPeriod = true
        this.modalBodyTitleTransletable =
          'ATTENTION_YOU_ARE_BOOKING_OUTSIDE_OF_THE_PROJECT_PERIOD_YOU_PROCEED_BUT_YOUR_PROJECT_MANAGER_WILL_BE_INFORMED'
        this.modalBodyTitle.next(this.translate.instant(this.modalBodyTitleTransletable))
      }
      this.checkOverbooking()
    } else {
      this.checkIfTimeSheetIsDuplicate(this.timesheetObject)
    }
  }

  checkIfTimeSheetIsDuplicate(body: any) {
    this.timeSheetService.checkIfTsIsDuplicate(body).subscribe((res: boolean) => {
      if (res) {
        this.openModalDoubleBookingSubject$.next(body)
      } else {
        this.createTSReq(body)
      }
    })
  }

  createTSReq(body: any) {
    this.timeSheetService.newTimeSheetEntery(body).subscribe((res: any) => {
      if (res.httpStatus === 'CREATED') {
        this.uploadFIle(res.response.id, body)
      } else if (res.httpStatus === 'BAD_REQUEST') {
        this.modalSaveEmitter.next(false)
      }
      this.closeModals()
    })
  }

  updateTimesheetApiCall(body: any) {
    body.id = this.timeSheet.id
    body.user = {
      id: this.user.id,
    }
    this.timeSheetService.updateTimeSheetEntery(body).subscribe({
      next: (res: any) => {
        if (res.httpStatus === 'OK') {
          this.uploadFIle(res.response.id, body)
        }
        this.closeModals()
      },
      error: (error: any) => {
        this.modalSaveEmitter.next(false)
        this.closeModals()
      },
    })
  }

  closeModals() {
    this.modalClose()
    this.closeModal()
  }

  uploadFIle(id: number, body: any) {
    if (this.files.length) {
      const fileName = this.files[0].name
      if (!fileName.includes('vps46017')) {
        this.timeSheetService.uploadFile(id, this.files[0]).subscribe((res: ApiResponse<Timesheet>) => {
          if (res.response) {
            this.modalSaveEmitter.next(body)
          }
        })
      } else {
        this.modalSaveEmitter.next(body)
      }
    } else {
      this.modalSaveEmitter.next(body)
    }
  }

  clearTimesheet() {
    this.timesheetForm.reset()
    this.selectedTasksUpdate = null
    this.files = []
  }

  async openModal() {
    this.route.queryParams.subscribe(params => {
      const showModalParam = params['showModal']

      if (showModalParam === 'trueCreate') {
        this.clearTimesheet()
        this.timesheetForm.patchValue({ startDate: this.dateAdapter.toModel(this.ngbCalendar.getToday()) })
      }
    })
    return await this.modalComponent
      .open({ windowClass: 'timesheet-body' })
      .then((res: any) => (res === 1 ? this.modalClose() : null))
  }

  async closeModal() {
    return await this.modalComponent.closeModal()
  }

  modalClose() {
    this.submit = false
    this.modalCloseEmitter.emit(true)
  }

  onSelect(event: any) {
    this.files = []
    this.files.push(...event.addedFiles)
  }

  onRemove(event: any) {
    this.files.splice(this.files.indexOf(event), 1)
  }

  isPdfFile(file: File) {
    return file['type'].split('.')[0] === 'application/pdf'
  }

  ValidateEndBreakTime(control: AbstractControl) {
    let startBreakTime = new Date(control.get('startPause')?.value)
    let endBreakTime = new Date(control.get('endPause')?.value)
    if (startBreakTime?.getTime() > endBreakTime?.getTime()) {
      return { invalidBreakTime: true }
    }
    return null
  }

  ValidateStartBreakTime(control: AbstractControl) {
    if (!control.get('startPause')?.value) {
      return null
    }
    let startBreakTime = new Date(control.get('startPause')?.value)
    let startWorkTime = new Date(control.get('start')?.value)
    if (startBreakTime?.getTime() < startWorkTime?.getTime()) {
      return { invalidStartBreakTime: true }
    }
    return null
  }

  ValidateEndWorkTime(control: AbstractControl) {
    if (!control.get('endPause')?.value) {
      return null
    }
    let endBreakTime = new Date(control.get('endPause')?.value)
    let endWorkingTime = new Date(control.get('end')?.value)
    if (endWorkingTime?.getTime() < endBreakTime?.getTime()) {
      return { invalidEndWorkingTime: true }
    }
    return null
  }

  fileToBase64 = (file: File): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader?.result?.toString()!)
      reader.onerror = error => reject(error)
    })
  }

  selectedDifferentProject(selectedProject: any) {
    this.project = selectedProject
    this.timesheetForm.patchValue({ project: selectedProject.id })
    this.total = 0
    this.usedAllocationInPercentage = 0
    this.usedAllocation = 0
    this.projectService.getProgresBarValue(this.user.id!, selectedProject.id).subscribe((res: any) => {
      this.total = res.response.allocation
      this.daysLeft = res.response.daysLeft
      this.projectBudget = res.response.usedAllocationInPercentage ?? 0
      this.usedAllocationInPercentage = res.response.usedAllocationInPercentage
      this.usedAllocation = res.response.usedAllocation
    })
    this.getCompanyAndProjectTasks(selectedProject.id)
  }

  getCompanyAndProjectTasks(selectedProjectId: string) {
    this.taskService.getAllCompanyAndProjectTasks(selectedProjectId).subscribe((res: any) => {
      let data = res.filter((task: any) => !task.archived)
      let companyTasks = data
        .filter((task: any) => task!.project == null)
        .sort((a: any, b: any) => a.name.localeCompare(b.name))
      let projectTasks = data
        .filter((task: any) => task!.project != null)
        .sort((a: any, b: any) => a.name.localeCompare(b.name))
      this.getAllTasksByProjectId = [
        { name: this.translate.instant('COMPANY_TASK_OVERVIEW'), values: companyTasks },
        { name: this.translate.instant('PROJECT_TASK_OVERVIEW'), values: projectTasks },
      ]
    })
  }

  getTimesheetValue() {
    if (!this.user || !this.timeSheet) {
      return
    }
    this.clearTimesheet()
    this.titleForModal.next(this.translate.instant('UPDATE_ENTRY_INFORMATION_FOR'))
    this.secondaryTitle.next(this.user.firstName + ' ' + this.user.lastName)
    let date = new Date(this.timeSheet.start)
    let ngbDateStruct = { day: date.getDate(), month: date.getMonth() + 1, year: date.getFullYear() }

    this.timesheetForm.patchValue({
      startDate: this.dateAdapter.toModel(ngbDateStruct),
      project: this.timeSheet?.project.id,
      start: this.timeSheet.start,
      startPause: this.timeSheet.startPause,
      endPause: this.timeSheet.endPause,
      end: this.timeSheet.end,
      descriptions: {
        id: this.timeSheet?.descriptions[0].id,
        text: this.timeSheet?.descriptions[0]?.text,
        tasks: this.timeSheet?.descriptions[0]?.tasks.map(x => x.id),
      },
    })

    this.selectedTasksUpdate = this.timeSheet.descriptions[0].tasks

    this.total = 0
    this.daysLeft = 0
    this.projectBudget = 0

    this.userService
      .getUsersOnProject(this.timeSheet.project.id, 0, 50, 'user.firstName')
      .subscribe((res: ApiResponse<ProjectMember[]>) => {
        this.timeSheet.project.projectMembers = res.content
        const projectMember = this.timeSheet.project.projectMembers.find(element => element.user.id === this.user.id)
        if (projectMember) {
          this.total = projectMember.allocation!
          this.daysLeft = this.total * ((100 - projectMember?.usedAllocationInPercentage!) / 100)
          this.projectBudget = projectMember?.usedAllocationInPercentage ?? 0
        }
      })

    if (this.timeSheet.file) {
      this.timesheetInputService
        .downloadFile(`${environment.downloadFilePath}${this.timeSheet.file}`)
        .subscribe((res: any) => {
          let fileMetadata
          if (this.timeSheet.file?.split('.').pop() === 'pdf') {
            fileMetadata = {
              type: 'application/pdf',
              lastModified: Date.now(),
            }
          } else {
            fileMetadata = {
              type: 'image/' + this.timeSheet.file?.split('.').pop(),
              lastModified: Date.now(),
            }
          }
          let newFile = new File([res], this.timeSheet.file!, fileMetadata)
          this.files.push(newFile)
        })
    }
  }

  get taskArray() {
    return (this.timesheetForm.get('descriptions') as FormGroup).get('tasks') as FormGroup
  }

  setSelectedTasks(event: any) {
    this.taskArray.setValue(event.map((val: any) => val.id))
  }
}
