import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core'
import { ModalConfig } from '../modal.config'
import { ModalComponent } from '../modal/modal.component'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { BehaviorSubject } from 'rxjs'
import { Timesheet } from 'app/pages/core/model/timesheet.model'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-modal-ask-user-for-double-booking',
  templateUrl: './modal-ask-user-for-double-booking.component.html',
  styleUrls: ['./modal-ask-user-for-double-booking.component.scss'],
})
export class ModalAskUserForDoubleBookingComponent {
  @ViewChild('modalCheckForDoubleBooking') private modalContent: TemplateRef<ModalComponent>

  @ViewChild('shareModal') shareModal!: TemplateRef<any>

  @Input() openModalSubject$: BehaviorSubject<any> = new BehaviorSubject<any>({})

  @Output() saveDoubleBooking: EventEmitter<any> = new EventEmitter<any>()

  timesheetForSaving: Timesheet
  modalReference: any

  constructor(private modalService: NgbModal) {}

  ngAfterViewInit(): void {
    this.modalReference = this.openModalSubject$.subscribe((res: Timesheet) => {
      if (res?.start) {
        this.timesheetForSaving = res
        this.modalService.dismissAll(); // Close any existing modals
        this.modalService.open(this.modalContent, {
          centered: true,
          size: 'md',
          windowClass: 'add-new-modal',
          backdrop: 'static',
          keyboard: false
        })
      }
    })
  }
}
