<app-modal #modal [modalConfig]="modalConfig" class="timesheet-body">
  <form [formGroup]="timesheetForm" nz-form>
    <div class="row first-row-wrapper">
      <div class="col-5 mb-3 date-wrapper">
        <label class="fs-6 fw-bolder required">{{ 'DATE' | translate }}</label>
        <div class="input-group">
          <input
            class="form-control form-control-lg form-control-solid"
            placeholder="dd.mm.yyyy"
            name="d2"
            ngbDatepicker
            #d2="ngbDatepicker"
            formControlName="startDate"
          />
          <button class="btn bi-calendar3" (click)="d2.toggle()" type="button"></button>
        </div>
        <div
          *ngIf="submit && timesheetForm.get('date')?.hasError('required')"
          class="fv-plugins-message-container invalid-feedback"
        >
          {{ 'ENTRY_IS_REQUIRED' | translate }}
        </div>
      </div>
      <div class="col-7 mb-3 project-wrapper">
        <label class="fs-6 fw-bolder required">{{ 'PROJECT' | translate }}</label>
        <div *ngIf="!specific" class="col-12 mb-3 mt-2">
          <ng-container *ngIf="isLoadingProjectList; else content">
            <div class="loading-message">{{ 'LOADING_PROJECTS' | translate }}....</div>
          </ng-container>
          <ng-template #content>
            <app-select
              [options]="projectList"
              [label]="'label'"
              type="primary"
              size="large"
              [selectedValue]="selectedProjectUpdate"
              (statusSelectChange)="selectedDifferentProject($event)"
            ></app-select>
            <div *ngIf="!projectList || projectList.length === 0" class="no-projects-asigned">
              <p>{{ 'NO_PROJECTS_FOUND' | translate }}</p>
            </div>
            <div
              *ngIf="submit && timesheetForm.get('project')?.hasError('required')"
              class="fv-plugins-message-container invalid-feedback"
            >
              {{ 'ENTRY_IS_REQUIRED' | translate }}
            </div>
          </ng-template>
        </div>
        <ng-container *ngIf="specific">
          <span class="form-control form-control-lg form-control-solid edit_project_specific">{{ project.name }}</span>
        </ng-container>
      </div>
    </div>
    <div *ngIf="timesheetForm.get('project')?.value" class="mb-3">
      <div class="col-12">
        <app-progress-bar [percentage]="projectBudget" [daysLeft]="daysLeft" [total]="total"></app-progress-bar>
      </div>
    </div>
    <div class="row">
      <div class="col-6 mt-2">
        <nz-form-label class="fs-6 fw-bolder required" nzFor="start">{{ 'START_WORK' | translate }}</nz-form-label>
        <nz-form-item class="input-group">
          <nz-form-control>
            <nz-time-picker
              class="form-control form-control-lg form-control-solid"
              id="start"
              name="start"
              formControlName="start"
              nzPlaceHolder="--:--"
              nzFormat="HH:mm"
            ></nz-time-picker>
          </nz-form-control>
        </nz-form-item>
        <div
          *ngIf="submit && timesheetForm.get('start')?.hasError('required')"
          class="fv-plugins-message-container invalid-feedback"
        >
          {{ 'ENTRY_IS_REQUIRED' | translate }}
        </div>
      </div>
      <div class="col-6 mt-2">
        <div class="input-group">
          <nz-form-label class="fs-6 fw-bolder required" nzFor="end">{{ 'END_WORK' | translate }}</nz-form-label>
          <nz-form-item class="input-group">
            <nz-form-control>
              <nz-time-picker
                class="form-control form-control-lg form-control-solid"
                id="end"
                name="end"
                formControlName="end"
                nzPlaceHolder="--:--"
                nzFormat="HH:mm"
              ></nz-time-picker>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div
          *ngIf="submit && timesheetForm.get('end')?.hasError('required')"
          class="fv-plugins-message-container invalid-feedback"
        >
          {{ 'ENTRY_IS_REQUIRED' | translate }}
        </div>
        <div
          *ngIf="submit && timesheetForm?.errors?.invalidEndWorkingTime"
          class="fv-plugins-message-container invalid-feedback"
        >
          {{ 'END_WORKING_TIME_IS_BEFORE_END_BREAK_TIME' | translate }}
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6 mb-3">
        <nz-form-label class="fs-6 fw-bolder" nzFor="startPause">{{
          'START_BREAK' | translate
        }}</nz-form-label>
        <nz-form-item class="input-group">
          <nz-form-control>
            <nz-time-picker
              class="form-control form-control-lg form-control-solid"
              id="startPause"
              name="startPause"
              formControlName="startPause"
              nzPlaceHolder="--:--"
              nzFormat="HH:mm"
            ></nz-time-picker>
          </nz-form-control>
        </nz-form-item>
        <div
          *ngIf="submit && timesheetForm?.errors?.invalidStartBreakTime"
          class="fv-plugins-message-container invalid-feedback"
        >
          {{ 'BREAK_TIME_CANT_START_BEFORE_YOU_START_WORKING' | translate }}
        </div>
      </div>
      <div class="col-6 mb-3">
        <nz-form-label class="fs-6 fw-bolder" nzFor="endPause">{{ 'END_BREAK' | translate }}</nz-form-label>
        <nz-form-item class="input-group">
          <nz-form-control>
            <nz-time-picker
              class="form-control form-control-lg form-control-solid"
              id="endPause"
              name="endPause"
              formControlName="endPause"
              nzPlaceHolder="--:--"
              nzFormat="HH:mm"
            ></nz-time-picker>
          </nz-form-control>
        </nz-form-item>
        <div
          *ngIf="submit && timesheetForm?.errors?.invalidBreakTime"
          class="fv-plugins-message-container invalid-feedback"
        >
          {{ 'BREAK_TIME_END_IS_BEFORE_BREAK_TIME_START' | translate }}
        </div>
      </div>
    </div>

    <div formGroupName="descriptions">
      <div class="d-flex align-items-center mb-3">
        <div class="col-3">
          <label class="fs-6 fw-bolder required position_label">{{ 'DESCRIPTION' | translate }}</label>
        </div>
        <div class="col-9">
          <textarea formControlName="text" class="form-control form-control-solid" style="resize: none"></textarea>
          <div
            *ngIf="submit && timesheetForm.get('descriptions.text')?.hasError('required')"
            class="fv-plugins-message-container invalid-feedback"
          >
            {{ 'ENTRY_IS_REQUIRED' | translate }}
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center mb-3" *ngIf="getAllTasksByProjectId.length > 0">
        <div class="col-3">
          <label class="fs-6 fw-bolder">{{ 'TASKS' | translate }}</label>
        </div>
        <div class="col-9">
          <app-multi-select
            class="task-input"
            [options]="getAllTasksByProjectId"
            (statusSelectChange)="setSelectedTasks($event)"
            [isTimesheetInput]="true"
            [selectedValue]="selectedTasksUpdate"
            [label]="'name'"
            [allowClear]="true"
            size="large"
            type="primary"
          >
          </app-multi-select>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center mb-3">
      <div class="col-3">
        <label class="fs-6 fw-bolder position_label">{{ 'FILE_UPLOAD' | translate }}</label>
      </div>
      <div class="col-9">
        <div
          class="custom-dropzone form-control"
          style="padding: 0"
          ngx-dropzone
          [multiple]="false"
          accept="image/jpeg,image/jpg,image/png,application/pdf"
          (change)="onSelect($event)"
        >
          <ngx-dropzone-label>
            <span
              [inlineSVG]="'assets/media/icons/duotune/files/fil010.svg'"
              class="mr-3"
              [setSVGAttributes]="{ width: '23', height: '23' }"
            ></span>
            <span>{{ 'CLICK' | translate }}</span>
          </ngx-dropzone-label>
          <div class="dropzone-display-div">
            <ngx-dropzone-image-preview
              ngProjectAs="ngx-dropzone-preview"
              class="image_preview"
              [ngClass]="{ showPdf: isPdfFile(f) }"
              *ngFor="let f of files"
              [file]="f"
              [removable]="true"
              (removed)="onRemove(f)"
            >
              <ngx-dropzone-label>
                <div
                  *ngIf="isPdfFile(f)"
                  style="
                    background-image: url('../../../assets/media/svg/files/pdf.svg');
                    width: 34px;
                    height: 35px;
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    margin-right: 15px;
                  "
                ></div>
                <a>{{ f.name }}</a>
              </ngx-dropzone-label>
            </ngx-dropzone-image-preview>
          </div>
        </div>
      </div>
    </div>
  </form>
</app-modal>
<app-modal-delete-superuser #modalDeleteSuperUser [modalConfig]="modalConfigDeleteSuperUser">
</app-modal-delete-superuser>
<app-modal-ask-user-for-double-booking
  [openModalSubject$]="openModalDoubleBookingSubject$"
  (saveDoubleBooking)="createTSReq($event)"
></app-modal-ask-user-for-double-booking>
