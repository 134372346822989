<div class="projects_wrapper">
  <div class="projects_controls">
    <div class="projects_controls_wrapper">
      <div *ngIf="showUsersProjects" style="margin-left: 15px">
        <div class="d-flex align-items-center">
          <div class="user-img-wrapper">
            <img [src]="userData.imageUrl" alt="user_img" />
          </div>
          <div class="user-name">
            {{ userData?.firstName + ' ' + userData?.lastName }}
          </div>
        </div>
      </div>
      <div class="filter-block">
        <app-select
          [options]="projectStatuses"
          [allowClear]="true"
          [selectedValue]="selectedStatus"
          [label]="'label'"
          type="primary"
          (statusSelectChange)="filterList($event)"
          size="large"
          [placeholder]="'PROJECT_STATUS_FILTER' | translate"
        ></app-select>
      </div>
    </div>
    <div class="projects_search_wrapper">
      <app-button
        *ngIf="authService.isProjectAdmin"
        (handleClick)="createNewProjectTemplate()"
        [title]="'ADD' | translate"
        buttonType="primary"
        prefixIcon="assets/media/icons/duotune/text/txt001.svg"
      ></app-button>
      <app-input
        search
        [placeholder]="'SEARCH' | translate"
        (statusSelectChange)="searchParam($event)"
        [(ngModel)]="searchTerm"
      ></app-input>
    </div>
  </div>
  <app-table
    class="project-table"
    [customHeadings]="tableHeadings"
    [sortableColumns]="sortableColumns"
    [dataList]="tableData"
    [pageSize]="5"
    [rowClickable]="true"
    [hiddenColumns]="['id']"
    [selectDisabled]="authService.currentUserRole !== 'PROJECT_ADMIN'"
    (rowClick)="redirectToSingleProjectPage($event)"
    [frontendPagination]="false"
    [pagination]="paginationObject"
    (paginationChange)="mapPagination($event)"
    (sortingChange)="mapSorting($event)"
    [sort]="sortObject"
  ></app-table>
</div>
