<form [formGroup]="form" *ngIf="form">
  <div class="row mt-2">
    <div class="col-md-6">
      <label class="form-label fs-6 fw-bolder required marginTop">{{ 'USERNAME' | translate }}</label>
      <app-input
        formControlName="username"
        #username
        [disabled]="update"
        [ngClass]="{
          'is-invalid': usernameExist || (submitted && formControls['username'].errors),
          'custom-class': update
        }"
      ></app-input>
      <div *ngIf="submitted && !usernameExist && formControls.username.errors?.required" class="invalid-feedback">
        {{ 'ENTRY_IS_REQUIRED' | translate }}
      </div>
      <div *ngIf="usernameExist && !blankSpace" class="invalid-feedback">
        {{ 'ENTRY_ALREADY_EXIST' | translate }}
      </div>
      <div *ngIf="blankSpace" class="exist">
        {{ 'USERNAME_CANNOT_CONTAIN_WHITE_SPACE' | translate }}
      </div>
    </div>
    <div class="col-md-6">
      <label class="form-label fs-6 fw-bolder required ms-2 mb-4">{{ 'USER_ROLE' | translate }}</label>
      <app-select
        class="mt-7"
        [options]="arr"
        [label]="'label'"
        [allowClear]="false"
        type="primary"
        size="large"
        [selectedValue]="selectedFilter"
        (statusSelectChange)="setValueForUserRole($event)"
        [inputDisable]="authService.currentUserRole !== 'PROJECT_ADMIN' || authService.currentUsername === form.get('username')?.value"
        [ngClass]="{ 'is-invalid': submitted && formControls['userRole'].errors }"
      >
      </app-select>
      <div *ngIf="submitted && formControls.userRole.errors?.required" class="invalid-feedback">
        {{ 'ENTRY_IS_REQUIRED' | translate }}
      </div>
    </div>
  </div>
  <div class="row mt-7">
    <div class="col">
      <label class="form-label fs-6 fw-bolder marginTop" [ngClass]="{ required: !formControls.noEmail.value! }">
        {{ 'E_MAIL' | translate }}</label
      >
      <app-input
        formControlName="email"
        [disabled]="authService.currentUserRole !== 'PROJECT_ADMIN'"
        [ngClass]="{
          'is-invalid': emailExist || (submitted && formControls['email'].errors),
          blur: form.get('noEmail')!.value,
          'custom-class': authService.currentUserRole !== 'PROJECT_ADMIN'
        }"
      >
      </app-input>
      <div *ngIf="submitted && formControls.email.errors?.required" class="invalid-feedback">
        {{ 'ENTRY_IS_REQUIRED' | translate }}
      </div>
      <div *ngIf="submitted && formControls.email.errors?.pattern" class="invalid-feedback">
        {{ 'USER_EMAIL_VALIDATION' | translate }}
      </div>
      <div *ngIf="emailExist" class="invalid-feedback">
        {{ 'EMAIL_TAKEN' | translate }}
      </div>
    </div>
    <div
      class="col-2 noMail"
      [hidden]="authService.currentUserRole !== 'PROJECT_ADMIN'"
      [ngClass]="{ 'no-click': authService.currentUserRole !== 'PROJECT_ADMIN' }"
    >
      <div class="form-check form-check-custom form-check-solid mail">
        <label class="fs-6 mb-2 fw-bolder" for="flexCheckDefault">
          {{ 'NO_MAIL' | translate }}
        </label>
        <input
          class="form-check-input form-control-lg"
          style="margin-bottom: -1px"
          [ngClass]="{ 'custom-class': authService.currentUserRole !== 'PROJECT_ADMIN' }"
          type="checkbox"
          value="1"
          id="flexCheckDefault"
          formControlName="noEmail"
          (change)="blur()"
        />
      </div>
    </div>
  </div>
  <div class="row mt-7">
    <div class="col-md-6">
      <label class="form-label fs-6 fw-bolder required marginTop">{{ 'FIRST_NAME' | translate }}</label>
      <app-input
        formControlName="firstName"
        [disabled]="authService.currentUserRole !== 'PROJECT_ADMIN'"
        [ngClass]="{
          'is-invalid': submitted && formControls['firstName'].errors,
          'custom-class': authService.currentUserRole !== 'PROJECT_ADMIN'
        }"
      ></app-input>
      <div *ngIf="submitted && formControls.firstName.errors?.required" class="invalid-feedback">
        {{ 'ENTRY_IS_REQUIRED' | translate }}
      </div>
      <div *ngIf="submitted && formControls.firstName.errors?.pattern" class="invalid-feedback">
        {{ 'FIRST_LAST_NAME_MESSAGE' | translate }}
      </div>
    </div>
    <div class="col-md-6">
      <label class="form-label fs-6 fw-bolder required ms-2 marginTop"> {{ 'LAST_NAME' | translate }}</label>
      <app-input
        style="margin-left: -1%"
        formControlName="lastName"
        [disabled]="authService.currentUserRole !== 'PROJECT_ADMIN'"
        [ngClass]="{
          'is-invalid': submitted && formControls['lastName'].errors!,
          'custom-class': authService.currentUserRole !== 'PROJECT_ADMIN'
        }"
      ></app-input>
      <div *ngIf="submitted && formControls['lastName'].errors?.required" class="invalid-feedback">
        {{ 'ENTRY_IS_REQUIRED' | translate }}
      </div>
      <div *ngIf="submitted && formControls['lastName'].errors?.pattern" class="invalid-feedback">
        {{ 'FIRST_LAST_NAME_MESSAGE' | translate }}
      </div>
    </div>
  </div>
  <div class="row mt-7">
    <div class="col-md-12">
      <label class="form-label fs-6 fw-bolder required marginTop">{{ 'JOB_TITLE' | translate }}</label>
      <app-input
        formControlName="jobTitle"
        [disabled]="authService.currentUserRole !== 'PROJECT_ADMIN'"
        [ngClass]="{ 'is-invalid': submitted && formControls['jobTitle'].errors }"
      ></app-input>
      <div *ngIf="submitted && formControls['jobTitle'].errors?.required" class="invalid-feedback">
        {{ 'ENTRY_IS_REQUIRED' | translate }}
      </div>
      <div *ngIf="formControls['jobTitle'].hasError('maxlength')" class="invalid">
        {{ 'MAXIMUM_CHARACTERS_IS_50' | translate }}
      </div>
    </div>
  </div>
</form>
